import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => setIsOpen(!isOpen);

  return (
    <div className="navbar-container">
      <div className={`navbar ${isSticky ? 'fixed' : ''}`}>
         <div className="navbar-logo">
          {/* Wrap the logo in a Link component */}
          <Link to="/">
            <img src="/careertrust.png" alt="CareerTrust Shield" className="logo" />
          </Link>
          <Link to="/" className="navbar-title">
            <h1>CareerTrust Shield</h1>
          </Link>
          
        </div>
        <button className="menu-toggle" onClick={toggleMenu}>
          ☰
        </button>
        <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
          <Link to="/">Home</Link>
          <Link to="/jobdescriptionanalysis">Is the Job Posting fake?</Link>
          <Link to="/chrome-extension">Download Our Chrome Extension</Link>
          <Link to="/job-opportunities">Discover Job Opportunities</Link>
          <Link to="/report-scam">Support for scam victims</Link>
          <Link to="/trivia">Stay Scam Smart</Link>
          <Link to="/pastscams">Explore Past Scams</Link>          
        </div>
      </div>
    </div>
  );
};

export default Navbar;
