import React from 'react';
import './ChromeExtension.css'; 
import NextStepButton from '../components/NextStepButton'

const ChromeExtension = () => {
  return (
    <div className="chrome-extension-container">
      <header className="extension-header">
        <h2>Check out our Chrome Extension</h2>
        <p>Enhance your job search experience with real-time job scam detection and security while browsing job listings.</p>
        <button className="download-btn" onClick={() => { 
          window.location.href = '/careertrustshield-extension.zip';
             }}
        > Download Extension
        </button>
      </header>

      <section className="features-section">
        <div className="feature-card">
          <i className="fas fa-shield-alt feature-icon"></i>
          <h3>Scam Detection</h3>
          <p>Stay safe with real-time alerts for potential job scams as you browse online job boards.</p>
        </div>

        <div className="feature-card">
          <i className="fas fa-bell feature-icon"></i>
          <h3>Instant Notifications</h3>
          <p>Receive immediate notifications for any suspicious job postings to keep your information secure.</p>
        </div>

        <div className="feature-card">
          <i className="fas fa-thumbs-up feature-icon"></i>
          <h3>Trusted Employers</h3>
          <p>Identify verified and trustworthy job listings with ease and confidence.</p>
        </div>
      </section>

      <section className="installation-steps">
        <h2>Steps on How to Download and Install Chrome Extension</h2>
        <ol>
        <li><strong>Hit the download button above</strong>&nbsp;  to grab your &nbsp;<code>.zip</code>&nbsp; file!</li>
        <li><strong>Unzip the file</strong>&nbsp;  and look for the folder named "CareerTrust Shield Chrome Extension."</li>
        <li><strong>Launch Google Chrome</strong>&nbsp;  and type &nbsp;<code>chrome://extensions/</code>&nbsp; in the address bar.</li>
        <li><strong>Enable "Developer mode"</strong>&nbsp;  by toggling the switch in the top right corner of the extensions page.</li>
        <li><strong>Select "Load unpacked"</strong>&nbsp;  from the top left side, then browse to find and select the "CareerTrust Shield Chrome Extension" folder.</li>
        <li>Your extension will be installed and visible on the extensions page—you're all set!</li> 
        </ol>
        <p>Congratulations! You've just armed yourself with CareerTrust Shield. Happy and safe job hunting!</p>
      </section>

      <NextStepButton 
                nextPage="/job-opportunities" 
                buttonText="Discover New Job Opportunities" 
            />
    </div>
  );
};

export default ChromeExtension;