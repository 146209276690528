// ScrollToTopButton.js
import React from 'react';
import './ScrollToTopButton.css'; 

const ScrollToTopButton = () => {
  const scrollToTop = () => {
    const body = document.body;
    const html = document.documentElement;

    console.log('Triggering scroll to top');

    body.scrollTop = 0; // For Safari
    html.scrollTop = 0; // For Chrome, Firefox, IE, and Opera
  };

  return (
    <button className="scroll-to-top-button" onClick={scrollToTop}>
      Back to Top
    </button>
  );
};

export default ScrollToTopButton;
