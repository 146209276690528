import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="footer-logo">
        <img src="/careertrust.png" alt="CareerTrust Shield" className="logo" />
      </div>

      <div className="footer-content">
        <div className="footer-acknowledgement">
          <h3>Acknowledgement</h3>
          <p>
            We acknowledge the Traditional Custodians of the lands across Australia on which we live and work. We acknowledge their connection to this Country and pay our respect to Elders past, present, and emerging.
          </p>
        </div>

        <div className="footer-links">
          <div className="column">
            <a href="/jobdescriptionanalysis">Job Posting Analysis</a>
            <a href="/pastscams">Past Scams Explorer</a>
            <a href="/trivia">Scam Smart Quiz</a>
          </div>
          <div className="column">
            <a href="/job-opportunities">Safe Job Opportunities</a>
            <a href="/chrome-extension">Chrome Extension</a>
            <a href="/report-scam">Report a Scam</a>
          </div>
          <div>
              <hr style={{ border: 'none', borderTop: '1px solid #ccc', width: '100%', margin: '20px 0' }} />
              <p style={{ textAlign: 'center' }}>Copyright &copy; 2024 CareerTrustShield. All rights reserved | Powered by TA33</p>
          </div>

        </div>
      </div>
    </footer>
  );
};

export default Footer;
