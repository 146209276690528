// src/pages/PastScams.js

import React , { useEffect } from 'react';
import Dashboard from '../components/Dashboard';
import './PastScams.css';

const PastScams = () => {

  return (
    
    <div className="scam-dashboard-container">
      {/* <h1 className="dashboard-title">Scam Statistics Dashboard: Your Window into Job Scam Trends</h1>
      <p className="dashboard-description">
        Welcome to CareerTrustShield's interactive Scam Statistics Dashboard. This powerful tool provides a comprehensive view of job scam trends across Australia, helping you stay informed and protected in your job search journey.
      </p> */}
    {/* // <div> */}
      {/* <div className="dashboard-content"> */}
        <Dashboard />
      {/* </div> */}
      <div className="why-this-matters">
        <h2>Why This Matters</h2>
        <p >By understanding the patterns and trends in job scams, you can:</p>
        <ul>
          <li>Identify potential risks relevant to your location and demographic</li>
          <li>Recognize common scam tactics used in your area</li>
          <li>Make informed decisions during your job search</li>
        </ul>
        <p>Remember, knowledge is your first line of defense against scams. Use this dashboard to empower yourself and others in the fight against job fraud.</p>
      </div>
    </div>
  );
};

export default PastScams;
