import { useNavigate } from 'react-router-dom';
import './NextStepButton.css'; // 引入CSS文件

const NextStepButton = ({ nextPage, buttonText }) => {
    const navigate = useNavigate();

    const goToNextPage = () => {
        navigate(nextPage);
    };

    return (
        <div className="next-step-button-container">
            <p className="next-step-title">What's next?</p> 
            <button onClick={goToNextPage} className="custom-next-button">
                {buttonText}
                <i className="fas fa-arrow-right" style={{ marginLeft: '8px' }}></i> 
            </button>
        </div>
    );
};

export default NextStepButton;
