import React, { useState, useEffect, useRef } from 'react';
import chatIcon from './chat-icon.png';
import icon from './icon.png';
import './Chatbot.css';
import predefinedQAData from './predefinedQA.json';
import stringSimilarity from 'string-similarity';  // npm install string-similarity

// Check if the browser supports Speech Recognition
const SpeechRecognition = window.SpeechRecognition || window.webkitSpeechRecognition;
const recognition = SpeechRecognition ? new SpeechRecognition() : null;

const Chatbot = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [query, setQuery] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [isListening, setIsListening] = useState(false); // New state for speech recognition

    const chatBodyRef = useRef(null);

    // Set up Speech Recognition
    useEffect(() => {
        if (recognition) {
            recognition.continuous = false;
            recognition.interimResults = false;
            recognition.lang = 'en-US';  // Set language to English or any other language you need

            recognition.onstart = () => {
                setIsListening(true);
            };

            recognition.onend = () => {
                setIsListening(false);
            };

            recognition.onresult = (event) => {
                const transcript = event.results[0][0].transcript;
                setQuery(transcript);  // Set recognized speech as query
                handleQuerySubmit(transcript); // Automatically submit the recognized speech
            };
        }
    }, []);

    useEffect(() => {
        if (!chatMessages.length) {
            setChatMessages([
                { type: 'bot', text: "Hi! You can click on one of the suggested queries below or type in a question to start." },
                {
                    type: 'bot',
                    jsx: (
                        <ul className="predefined-questions-list">
                            {predefinedQAData.slice(0, 3).map((qa, index) => (
                                <li
                                    key={index}
                                    className="predefined-question"
                                    onClick={() => handlePredefinedQuestionClick(qa.question)}
                                >
                                    {qa.question}
                                </li>
                            ))}
                        </ul>
                    ),
                }
            ]);
        }
    }, []);

    useEffect(() => {
        if (chatBodyRef.current) {
            chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
        }
    }, [chatMessages]);

    const toggleChatbot = () => {
        setIsOpen(!isOpen);
    };

    const getClosestPredefinedAnswer = (userQuery) => {
        const questionsAndAliases = predefinedQAData.flatMap((qa, index) => {
            return [{ question: qa.question, index }].concat(
                qa.aliases.map(alias => ({ question: alias, index }))
            );
        });

        const questionTexts = questionsAndAliases.map(qa => qa.question);
        const bestMatch = stringSimilarity.findBestMatch(userQuery, questionTexts);

        if (bestMatch.bestMatch.rating > 0.5) {
            const bestMatchIndex = questionsAndAliases[bestMatch.bestMatchIndex].index;
            return predefinedQAData[bestMatchIndex];
        }
        return null;
    };

    const queryDjangoBackend = async (question) => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/scamreports/open_ai/`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ user_input: question })
            });
            const data = await response.json();
            return response.ok ? data.response : "Sorry, I couldn't find an answer.";
        } catch (error) {
            console.error('Error fetching response from Django backend:', error);
            return "Sorry, an error occurred while fetching the response.";
        }
    };

    const handlePredefinedQuestionClick = (question) => {
        if (!question.trim()) return;
        handleQuerySubmit(question);
    };

    const handleQuerySubmit = async (question) => {
        if (!question.trim()) return;

        const userMessage = { type: 'user', text: question };
        setChatMessages(prevMessages => [...prevMessages, userMessage]);

        const predefinedAnswer = getClosestPredefinedAnswer(question);

        if (predefinedAnswer) {
            const botMessage = {
                type: 'bot',
                jsx: (
                    <>
                        {predefinedAnswer.answer}{' '}
                        {predefinedAnswer.linkText && predefinedAnswer.linkUrl && (
                            <a href={predefinedAnswer.linkUrl} target="_blank" rel="noopener noreferrer">
                                {predefinedAnswer.linkText}
                            </a>
                        )}
                    </>
                )
            };
            setChatMessages(prevMessages => [...prevMessages, botMessage]);
        } else {
            const djangoResponse = await queryDjangoBackend(question);
            const botMessage = { type: 'bot', text: djangoResponse };
            setChatMessages(prevMessages => [...prevMessages, botMessage]);
        }

        setQuery('');
    };

    // Start or stop listening for voice input
    const toggleListening = () => {
        if (isListening) {
            recognition.stop();
        } else {
            recognition.start();
        }
    };

    return (
        <div className="chatbot-container">
            {!isOpen && (
                <div className="chatbot-icon" onClick={toggleChatbot}>
                    <img src={chatIcon} alt="Chatbot Icon" />
                </div>
            )}

            {isOpen && (
                <div className="chatbot-window">
                    <div className="chatbot-header">
                        <img src={icon} alt="Chat Icon" />
                        <div className='chatbot-header h3'><h3>MEET AMY</h3></div>
                        <button onClick={toggleChatbot}>X</button>
                    </div>

                    <div className="chatbot-body" ref={chatBodyRef}>
                        {chatMessages.map((message, index) => (
                            <div key={index} className={`chat-message-container ${message.type === 'user' ? 'user-message-container' : 'bot-message-container'}`}>
                                {message.type === 'bot' && (
                                    <img src={icon} alt="Chat Icon" className="bot-icon-outside" />
                                )}
                                <div className={`chat-message ${message.type === 'user' ? 'user-message' : 'bot-message'}`}>
                                    {message.jsx || message.text}
                                </div>
                            </div>
                        ))}
                    </div>

                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            handleQuerySubmit(query);
                        }}
                        className="chatbot-form"
                    >
                        <textarea
                            placeholder="Ask away!"
                            value={query}
                            onChange={(e) => setQuery(e.target.value)}
                            rows="1"
                            className="chatbot-input"
                        />
                        <button type="submit">Send</button>
                    </form>

                    {/* Add button to start/stop voice input */}
                    {recognition && (
                        <button onClick={toggleListening} className="voice-button">
                            {isListening ? "Stop Listening" : "Start Voice Input"}
                        </button>
                    )}
                </div>
            )}
        </div>
    );
};

export default Chatbot;
