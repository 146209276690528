import React, { useState , useEffect, useRef } from 'react';
import axios from 'axios';
import './JobDescriptionAnalysis.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import icon from './icon.png';
import Tesseract from 'tesseract.js';
import { SpinnerDotted } from 'spinners-react'; // Import the Spinner component
import DOMPurify from 'dompurify';


const JobDescriptionAnalysis = () => {
    // State hooks
    const [jobDescription, setJobDescription] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const [imageText, setImageText] = useState(null);
    const [imageFileName, setImageFileName] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [options, setOptions] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [isChatbotOpen, setIsChatbotOpen] = useState(false);
    const [isTextMode, setIsTextMode] = useState(false);
    const fileInputRef = React.useRef();
    const [isJobDescriptionExpanded, setIsJobDescriptionExpanded] = useState(false); // Define state for the expansion

    const [trustRating, setTrustRating] = useState(null);
    // State for processing messages
    const [processingMessages, setProcessingMessages] = useState([]);
    const [spinnerVisible, setSpinnerVisible] = useState([]); // State for spinner visibility
    const [showFullDescription, setShowFullDescription] = useState(false);

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isImage, setIsImage] = useState(false);
    const [redFlags, setRedFlags] = useState('');
    const [scamKeywords, setScamKeywords] = useState(false);
    const [misspelledWords, setMisspelledWords] = useState(false);
    const [messageInterval, setMessageInterval] = useState(null);
    const [isProcessingComplete, setIsProcessingComplete] = useState(false); // State to track processing completion

 
    const handleDescriptionToggle = () => {
        setIsJobDescriptionExpanded((prev) => !prev);
    };
    const renderChatMessages = () => {
        return chatMessages.map((msg, index) => {
            if (msg.from === 'user') {
                return <div key={index} className='user-bubble'>{msg.message}</div>;
            }
    
            // Bot message: check if `msg.message` is an object (with `displayedLines` and `remainingLines`)
            if (msg.from === 'bot') {
                // if (typeof msg.message === 'string') {
                //     return (
                //         <div key={index} className='chatbot-bubble'>
                //             {msg.message.split('\n').map((line, i) => (
                //                 <React.Fragment key={i}>
                //                     {line}
                //                     <br />
                //                 </React.Fragment>
                //             ))}
                //         </div>
                //     );
                // }
            
    
                if (typeof msg.message === 'object') {
                    const { displayedLines, remainingLines, showMore } = msg.message;
    
                    return (
                        <div key={index} className='chatbot-bubble'>
                            <p>{displayedLines}</p>
                            {showMore && (
                                <div>
                                    <button className = 'read-more-button'
                                        onClick={handleDescriptionToggle}
                                        style={{ marginLeft: '5px', cursor: 'pointer' }}
                                    >
                                        {isJobDescriptionExpanded ? "Read Less" : "Read More"}
                                    </button>
    
                                    {isJobDescriptionExpanded && <p>{remainingLines}</p>}
                                </div>
                            )}
                        </div>
                    );
                } else {
                    // For regular text-based bot messages
                    if (msg.from === 'bot') {
                        const lines = msg.message.split('\n'); // Split the message by newlines
                        return (
                            <div key={index} className='chatbot-bubble'>
                                {lines.map((line, lineIndex) => (
                                    <div key={lineIndex}>{line}</div> // Render each line in a separate div
                                ))}
                            </div>
                        );
                    }
            
                }
            }
    
            return null; // Handle any other cases, if needed
        });
    };

    // Initial message
    const initialMessage = `Please enter a job description that has the following details: \n 1. Company Title\n 2. Job Title \n 3. Job Description\n 4. Requirements (Education and experience) \n 5. Benefits \n Provide as many details as possible to get better results `;
    // const initialMessage = initialMessage_raw.replace(/\n/g, '<br>');
    const handleJobApplicationResponse = (response) => {
        setChatMessages((prev) => [
            ...prev,
            { from: 'user', message: response },
        ]);
    
        if (response.toLowerCase() === 'yes') {
            setChatMessages((prev) => [
                ...prev,
                { from: 'bot', message: 'Please visit our scam support page.' },
            ]);
            // Add button to direct to the scam support page
            setOptions(['Go to Scam Support']);
        } else {
            setChatMessages((prev) => [
                ...prev,
                { from: 'bot', message: 'Good job coming to us!' },
            ]);
        }
    };
    
    // Handle opening chatbot
    const openChatbot = (type) => {
        if (type === 'image') {
            fileInputRef.current.click();
            setIsTextMode(false);
        } else {
            setIsTextMode(true);
            setIsChatbotOpen(true);
            setChatMessages([{ from: 'bot', message: initialMessage }]);
        }
    };
    function formatJobDescription(description) {
        const lines = description.split('\n'); // Split into lines
        const displayedLines = lines.slice(0, 3).join('\n'); // First 3 lines
        const remainingLines = lines.slice(3).join('\n'); // Rest of the lines
    
        const showMore = remainingLines.length > 0; // Show "Read More" if there's more text
        return { displayedLines, remainingLines, showMore };
    };
    
    
    // Handle image change
    const handleImageChange = async (e) => {
        const file = e.target.files[0];
        if (file) {
            setImageFile(file);
            setImageFileName(file.name);
            setError('');
            setIsChatbotOpen(true);
            setIsTextMode(false);
            setChatMessages([{ from: 'bot', message: "Analyzing the uploaded image..." }]);
            await handleSubmitImage(file);
            const reader = new FileReader(); // Create a FileReader instance
            reader.onloadend = () => {
                setImageSrc(reader.result); // Set imageSrc to the data URL of the image
            };
            reader.readAsDataURL(file);
        }
    };
    const [imageSrc, setImageSrc] = useState(null); 
    // Submit image
    const handleSubmitImage = async (image) => {
        setLoading(true);
        try {
            const { data: { text } } = await Tesseract.recognize(image, 'eng', { logger: (m) => console.log(m) });
            setImageText(text);
            // Start processing messages after getting the text
            handleSubmit(text)
        } catch (err) {
            setError('Error processing the image. Please try again.');
            setLoading(false);

        }finally{
            setIsImage(true);
           
        }
    };

    // Handle text input change
    const handleDescriptionChange = (e) => {
        setJobDescription(e.target.value);
        setError('');
    };
    const chatEndRef = useRef(null); // Create a ref for the end of the chat

    useEffect(() => {
        // Scroll to the last message when chatMessages changes
        if (chatEndRef.current) {
            chatEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [chatMessages]);
    const handleUserResponse = async (option) => {
        console.log('Red Flags:', redFlags); // Check values
        console.log('Scam Keywords:', scamKeywords);
        console.log('Misspelled Words:', misspelledWords);

        setChatMessages((prev) => [
            ...prev,
            { from: 'user', message: option },
        ]);
        const cleanedRedFlags = redFlags.replace(/[#*]/g, '');

        // Use regex to split and format red_flags
        const formattedRedFlags = cleanedRedFlags
            .split(/(?=\d+\.\s)/) // Split on digits with period and space
            .map(flag => flag.trim()) // Trim whitespace
            .filter(flag => flag) // Remove any empty entries
            .map(flag => {
                // Ensure the format is "1. Text"
                const [number, ...textParts] = flag.split('.'); // Split on the first period
                return `${number.trim()}. ${textParts.join('.').trim()}`; // Reconstruct the string
            });
        // Check which option was selected
        if (option === 'Show Red Flags' && redFlags) {
            setChatMessages((prev) => [
                ...prev,
                { from: 'bot', message: 'Here are the red flags:' },
                ...formattedRedFlags.map(flag => ({ from: 'bot', message: flag })),
            ]);
            setOptions((prevOptions) => prevOptions.filter(opt => opt !== option));  // Clear the options
        } else if (option === 'Show Scam Keywords' && scamKeywords) {
            setChatMessages((prev) => [
                ...prev,
                { from: 'bot', message: 'Here are the scam keywords:' },
                { from: 'bot', message: scamKeywords.join('\n') }, // Join keywords with newline
            ]);
            setOptions((prevOptions) => prevOptions.filter(opt => opt !== option)); 
        } else if (option === 'Show Misspelled Words' && misspelledWords) {
            setChatMessages((prev) => [
                ...prev,
                { from: 'bot', message: 'Here are the misspelled words:' },
                { from: 'bot', message: misspelledWords.join('\n') }, // Join misspelled words with newline
            ]);
            setOptions((prevOptions) => prevOptions.filter(opt => opt !== option)); 
        } else if (option === 'What else?') {
            setChatMessages((prev) => [
                ...prev,
                { from: 'bot', message: 'No problem! Clearing all options.' },
            ]);
            setOptions([]); // Clear all options
            setChatMessages((prev) => [
                ...prev,
                { from: 'bot', message: 'Have you already applied for this job?' },
            ]);
            setOptions(['Yes', 'No']);
            // If trust rating is less than 70, ask if they have applied for the job.
        }else if (trustRating < 70 && option === 'What else?') {
                // Ask if they have applied for the job after clicking "What else?"
                setChatMessages((prev) => [
                    ...prev,
                    { from: 'bot', message: 'Have you already applied for this job?' },
                ]);
                setOptions(['Yes', 'No']); // Offer "Yes" and "No" options
            } else if (trustRating < 70 && option === 'Yes') {
                // User clicked Yes (they applied for the job)
                setChatMessages((prev) => [
                    ...prev,
                    { from: 'bot', message: 'Please visit our scam support page for further assistance.' },
                    { from: 'bot', message: 'Thank you for chatting with us.' },
                ]);
                setOptions([]); // Clear options
            } else if (trustRating < 70 && option === 'No') {
                // User clicked No (they haven't applied for the job)
                setChatMessages((prev) => [
                    ...prev,
                    { from: 'bot', message: 'Good job for being cautious! Feel free to analyze more job listings.' },
                    { from: 'bot', message: 'Thank you for chatting with us.' },
                ]);
                setOptions([]); // Clear options
            }
        
    };
    
    
    // Display options for user to click
    const renderOptions = () => {
        let currentOptions = [];
    
        if (trustRating < 70 && !options.length) {
            currentOptions = ['Yes', 'No'];
        } else {
            currentOptions = options; // Use existing options
        }
    
        return currentOptions.map((option, index) => (
            <div className="user-option-button-container" key={option}> {/* Using option as a key */}
                <button
                    className="user-option-button"
                    onClick={() => handleUserResponse(option)}
                >
                    {option}
                </button>
            </div>
        ))
    };
    
   

    // Start processing messages
    const startProcessingMessages = async () => {
        // Clear previous messages and reset spinner visibility
        setProcessingMessages([]);
        setSpinnerVisible([]); // Initialize spinner visibility
    
        const messages = [
            "Performing description analysis...",
            "Analyzing job description for potential issues...",
            "Calculating trust rating and red flags..."
        ];
    
        // Create a promise that resolves after all messages have been displayed
        return new Promise(async (resolve) => {
            for (let i = 0; i < messages.length; i++) {
                // Show spinner first
                setProcessingMessages((prev) => [...prev, messages[i]]);
                setSpinnerVisible((prev) => [...prev, true]); // Show spinner
    
                await new Promise((innerResolve) => {
                    setTimeout(() => {
                        // After 5 seconds, change spinner to tick
                        setSpinnerVisible((prev) => {
                            const updated = [...prev];
                            updated[i] = false; // Change current spinner to tick mark
                            return updated;
                        });
                        innerResolve(); // Resolve the inner promise
                    }, 2500); // Show each message for 5 seconds
                });
            }
    
            resolve(); // Resolve the outer promise after all messages have been displayed
        });
    };
    
    
    const handleRestart = () => {
        
        setChatMessages([]); // Clear chat messages
        setChatMessages([{ message: initialMessage, from: 'bot' }]);
        setJobDescription(''); // Clear input
        setIsSubmitted(false); // Enable text field again
        setProcessingMessages([]);
        setLoading(false); 
        setOptions([]);
       
    };
    
    // Handle submission
    const handleSubmit = async (description = jobDescription || imageText) => {
        // Check if the description is provided
        if (!description) {
            setError('Please provide a job description or upload an image.');
            return;
        }
        
        setIsProcessingComplete(false);
        console.log('isSubmitted:', isSubmitted);
        // Define validation constants
        const minLength = 50;
        const maxLength = 5000;
        const regex = /^[a-zA-Z0-9\s,.'“”‘’():•–-]*$/; // Updated regex to include additional valid characters

        if (description.length< minLength) {
            setError(`Job description must be at least ${minLength} characters long.`);
            return;
        }
        
        if (description.length > maxLength) {
            setError(`Job description cannot exceed ${maxLength} characters.`);
            return;
        }
        const sanitizedDescription =description;
        console.log(sanitizedDescription);

        // Validation checks
        if (sanitizedDescription.length < minLength && sanitizedDescription.length < description.length) {
            setError(`Please enter a valid job description`);
            return;
        }
    
        if (sanitizedDescription.length > maxLength) {
            setError(`Job description cannot exceed ${maxLength} characters.`);
            return;
        }
    
        // if (!regex.test(sanitizedDescription)) {
        //     setError('Job description contains invalid characters.');
        //     return;
        // }
    
        // Clear error if validation passes
        setError('');
    
        setLoading(true);
        setIsSubmitted(true);
        const { displayedLines, remainingLines, showMore } = formatJobDescription(sanitizedDescription);
        
        // Add the user’s job description to chat messages
        setChatMessages((prev) => [
            ...prev,
            {
                from: 'user',
                message: displayedLines  // Only pass the text here, not the object
            },
            {
                from: 'bot',
                message: { displayedLines, remainingLines, showMore } // Pass object for bot messages
            }
        ]);
        
        const processingPromise = startProcessingMessages();
        const formData = { description: sanitizedDescription };
        

        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/scamreports/process_user_response/`, formData);
            const { trust_rating, red_flags, scam_keywords, misspelled } = response.data;
    
            setTrustRating(trust_rating); // Set the trust rating
            setRedFlags(red_flags); // Set red flags
            setScamKeywords(scam_keywords); // Set scam keywords
            setMisspelledWords(misspelled); // Set misspelled words
            setChatMessages((prev) => [
                ...prev,
                { from: 'bot', message: `Trust Rating: ${trust_rating}` },
            ]);
    
            if (trust_rating < 70) {
                const options = ['What else?'];
            
                if (red_flags.length > 0) {
                    options.unshift('Show Red Flags');
                }
            
                if (misspelled.length > 0) {
                    options.unshift('Show Misspelled Words');
                }
            
                if (scam_keywords.length > 0) {
                    options.unshift('Show Scam Keywords');
                }
            
                // If there are any valid options (besides 'What else?')
                if (options.length > 1) {
                    setChatMessages((prev) => [
                        ...prev,
                        { from: 'bot', message: 'Would you like to dig deeper to find out the reasons?' },
                    ]);
                    setOptions(options);
                }  else {
                setChatMessages((prev) => [
                    ...prev,
                    { from: 'bot', message: 'You can proceed with this job listing.' },
                ]);
    
                setOptions([]); // Clear options if trust rating is good
            }
    
        }} catch (error) {
            console.error('Error analyzing the text:', error);
            setError('Error analyzing the text. Please try again.');
        } finally {
            await processingPromise;
            setLoading(false);
            setIsProcessingComplete(true); 
            
        }
    };
    
    // Utility function to sanitize input
    const sanitizeInput = (input) => {
        // Use DOMPurify to sanitize the input
        const sanitized = DOMPurify.sanitize(input);
    
        // Additional SQL injection prevention (optional, depending on your use case)
        const sqlInjectionSafe = sanitized
            .replace(/(SELECT|INSERT|UPDATE|DELETE|FROM|WHERE|AND|OR|;|--|#)/gi, ''); // Simple SQL injection prevention
    
        return sqlInjectionSafe;
    };
    
    function refreshPage(){
        window.location.reload(true);
    };

    // Start over
    const handleStartOver = () => {
        setJobDescription('');
        setImageFile(null);
        setImageFileName('');
        setImageText('');
        setChatMessages([]);

        setOptions([]);
        setError('');
        setLoading(false);
        setIsChatbotOpen(false);
        setIsTextMode(false);
        setTrustRating(null);
        setProcessingMessages([]); // Reset processing messages
        setSpinnerVisible([]); // Reset spinner visibility
        refreshPage();
    };

    return (
        <div className="job-analysis-page">
            <div className="logo-container">
                <img src={icon} alt="Chat Icon" className="bot-icon-page" />
            </div>
            <div className="jd-header">
                <h1>Let me take a look</h1>
            </div>
            <p className="instruction-text">
                To help me investigate, please upload a clear image or add a text of a job description.
            </p>
    
            <div className="user-options">
                <div className="option-container">
                    <button className="option-button-user" onClick={() => openChatbot('image')}>
                        <i className="fas fa-image"></i> Add Image
                    </button>
                    <input
                        ref={fileInputRef}
                        type="file"
                        accept="image/*"
                        onChange={handleImageChange}
                        className="file-input"
                        style={{ display: 'none' }} // Hide the input field
                    />
                </div>
                <div className="option-container">
                    <button className="option-button-user" onClick={() => openChatbot('text')}>
                        <i className="fas fa-font"></i> Add Text
                    </button>
                </div>
            </div>
    
            {isChatbotOpen && (
                <div className="chatbot-modal">
                    <div className="chatbot-content">
                        <div className="chatbot-header">
                        
                        <center><div className="text-header-container">
                                Job Description Analysis with Amy
                            </div></center>
                            <img src={icon} alt="Dinosaur Icon" className="new-icon" />
                            <button className="close-button" onClick={handleStartOver}>
                                &times;
                            </button>
                        </div>
                        <div>
                         
                        </div>
    
                        <div className="chatbot-body-job">
                            
                                <div className="left-container">
                                {isSubmitted && !isImage ? (
                                        <textarea
                                            className="job-description-input"
                                            placeholder="Enter a job description"
                                            value={jobDescription}
                                            onChange={handleDescriptionChange}
                                            disabled={isSubmitted} 
                                        />
                                    ) : isImage ? (
                                        <img
                                            src={imageSrc} // Replace with the appropriate source for your image
                                            alt="Uploaded Description"
                                            className="uploaded-image" // Add any desired class for styling
                                        />
                                    ) : null}   

                            {/* Show processing messages below the submit button */}
                            {processingMessages.length > 0 && (
                                <div className="processing-messages">
                                    {processingMessages.map((msg, index) => (
                                        <div key={index} className="processing-message">
                                            {spinnerVisible[index] ? (
                                                <SpinnerDotted size={20} thickness={200} color="#00A300" /> // Spinner
                                            ) : (
                                                <i className="fas fa-check" style={{ color: 'green' }}></i> // Check mark
                                            )}
                                            <span className='span-text'>{msg}</span>
    
                                            {/* Spinning Dinosaur Icon */}
                                            {spinnerVisible[index] && (
                                                <img
                                                    src={icon}
                                                    alt="Spinning Dinosaur"
                                                    className="dinosaur-icon"
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                            )}
                            {isTextMode && (
                                <div>   
                                    {isSubmitted && isProcessingComplete&& (
                                        <div className="button-container">
                                            {/* <button className="submit-button" onClick={handleEdit}>Edit Description</button> */}
                                            <button className="start-over-button" onClick={handleRestart}>Start Over</button>
                                        </div>
                                    )}
                                </div>
                            )}
                            
                            {isImage && (
                                <div>   
                                    { (
                                        <div className="button-container">
                                            
                                             <button className="start-over-button" onClick={handleStartOver}>Start Over</button>
                                        </div>
                                    )}
                                </div>
                            )}
                            </div>
                            <div className="right-container">
                                <div className="chat-container">
                                    {renderChatMessages()}
                                    {/* Render Options if they exist */}
                                    {options.length > 0 && (
                                        <div className="user-options">
                                            {renderOptions()} {/* This will render the buttons for the options */}
                                        </div>
                                    )}
                                    <div ref={chatEndRef} /> 
                                </div>
                                {error && <p className="error">{error}</p>}
                            
                            
    
                            {isTextMode && !isSubmitted && (
                                <div >
                                <textarea
                                    className="job-description-input"
                                    placeholder="Enter a job description"
                                    value={jobDescription}
                                    onChange={handleDescriptionChange}
                                    disabled={isSubmitted} 
                                />
                                    <center>
                                    <button
                                        className="submit-button"
                                        onClick={() => handleSubmit()}
                                    >
                                        {'Submit'}

                                    </button>
                                    </center>
                                 </div>   
                            )}
                            

                            </div>
                        </div>
                    </div>
                </div>
            )}
            
        </div>
    );
    
};

export default JobDescriptionAnalysis;
