import React, { useState } from 'react';
import FlipCard from './FlipCard';
import './FlipCardList.css';  

const cardData = [
  { 
    title: 'The Bait and Switch', 
    front: 'You’ve got an interview for a dream job! 🎉 \n\n', 
    back: 'But wait… it’s actually for a position you didn’t apply for! This classic scam lures you in with a shiny title, only to switch it up once you’re in the door. Remember, if it feels fishy, it probably is! 🐟' 
  },
  { 
    title: 'The “Career Consultant”', 
    front: 'Congratulations! You’ve been ‘selected’ by a career consultant! 💼\n\n', 
    back: 'But hold your horses! They’re just trying to sell you resume services. If they ask for money upfront, it’s time to run faster than your last job interview! 🏃‍♂️💨' 
  },
  { 
    title: 'The Direct Deposit Trap', 
    front: 'High pay for minimal work? Sounds too good to be true! 💰\n\n', 
    back: 'If they ask for your bank info to set up direct deposit before you even meet, it’s a scam! Unless you want to fund their vacation instead of your bank account! 🌴✈️' 
  },
  { 
    title: 'The Shipping Scam', 
    front: 'Work from home and get paid to repackage items? What a deal! 📦 \n\n', 
    back: 'Spoiler alert: you’re just a pawn in a postal fraud game! You’ll end up with stolen goods and a hefty bill. Unless you want to be a ‘professional package sender,’ steer clear! 🚫📬' 
  },
  { 
    title: 'The Fake Job Posting', 
    front: 'Job offers that sound too good to be true? 🎊 \n\n', 
    back: 'If the salary is sky-high and the requirements are non-existent, it’s probably a scam. Remember, if they’re offering you the moon, they might just be trying to sell you a rocket! 🚀' 
  },
  { 
    title: 'The Unsolicited Offer', 
    front: 'You didn’t apply, but they found your resume! 📄\n\n', 
    back: 'If they’re cold-calling you with a job offer, ask yourself: why are they so desperate? Unless you’re a unicorn, it’s likely a scam! 🦄' 
  },
  { 
    title: 'The Upfront Payment', 
    front: 'Pay to play? They want you to buy equipment first! 💻\n\n', 
    back: 'If they ask you to purchase fancy software or equipment to ‘start’ your job, it’s a scam! Unless you’re auditioning for a tech infomercial, don’t fall for it! 📺' 
  },
  { 
    title: 'The Grammatical Nightmare', 
    front: 'Job description full of typos? 🤔\n\n', 
    back: 'If it looks like it was written by a toddler with a typewriter, it’s probably a scam! Legit companies hire professionals, not grammar gremlins! 🧙‍♂️' 
  },
  { 
    title: 'The Impersonation Game', 
    front: 'Someone claims to be from a reputable company! 📞\n\n', 
    back: 'If they’re using a personal email or asking for your info over social media, it’s a scam! Legit companies don’t slide into your DMs for job offers! 📩' 
  },
  { 
    title: 'The Employment Contract Red Flag', 
    front: 'You received a job offer without an interview! 🎊\n\n', 
    back: 'If they send you an employment contract without even meeting you, it’s a scam! Unless you’re a secret agent, real jobs require a little more vetting! 🕵️‍♂️' 
  },
];

const FlipCardList = () => {
  const [flippedCards, setFlippedCards] = useState(Array(cardData.length).fill(false));

  const handleFlip = (index) => {
    setFlippedCards(prevState => 
      prevState.map((isFlipped, i) => (i === index ? !isFlipped : isFlipped))
    );
  };

  return (
    <div className="card-grid">
      {cardData.map((card, index) => (
        <FlipCard
          key={index}
          title={card.title}
          frontText={card.front}
          backText={card.back}
          isFlipped={flippedCards[index]}
          setIsFlipped={() => handleFlip(index)}
        />
      ))}
    </div>
  );
};

export default FlipCardList;
